<template lang="pug">
  .example-full.p-2.position-relative.pb-5(data-cy="nova-drag-drop-file-upload")
    .drop-active(v-show="$refs.upload && $refs.upload.dropActive")
      h3 Drop files to upload
    div(v-if="!value.length && !files.length")
      .d-flex.flex-column.align-items-center.justify-content-center.p-5
        img(:src="require(`@/assets/images/search.svg`)", style="height:150px;")
        span.font-lg.font-weight-bold.mt-3 No documents uploaded yet 😐
        label.btn.btn-lg.btn-outline-dark.py-2.mt-4(:for="name")
          .d-flex.justify-content-center.align-items-center
            span.mr-2 Upload Documents
            n-icon(name="cloud-upload")
    .upload(v-show="files.length || value.length")
      n-spinner(:active="loading", background-color="rgba(255,255,255,0.5)")
      .d-flex.justify-content-between.align-items-center.border-bottom.pb-2.px-2.pt-2
        span.font-md.font-weight-semibold {{ value.length }} Files Uploaded
        .d-flex(v-if="!isReviewModal")
          .btn.btn-outline-dark.d-inline-flex.align-items-center(@click="downloadZip", v-if="allowZipDownload")
            .d-flex(v-if="zipFiles.processing")
              b-spinner.mr-2( small)
              .text-gray-900 Creating zip...
            .d-flex.text-success(v-else-if="zipFiles.url")
              i.icon-download.mr-2
              .text-success Download Ready
            .d-flex(v-else)
              i.icon-download.mr-2
              .text-gray-900 Download zip

          file-upload.btn.btn-outline-dark.d-inline-flex.align-items-center(
            v-if="showUploadButton"
            ref="upload",
            :extensions="extensions",
            :disabled="disabled",
            :accept="accept",
            :multiple="multiple",
            :directory="directory",
            :size="size || 0",
            :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread",
            :drop="drop",
            :drop-directory="dropDirectory",
            v-model="files",
            @input-filter="inputFilter",
            @input-file="inputFile",
            :requiredDetails="requiredDetails")
            i.icon-attachment.mr-2
            span Upload Files
      .row(v-for="(File, index) in files", :key="File.id")
        .col-md-2.pt-3.text-center(v-if="(isProductTypeClaims && File.progress !== '0.00') || !isProductTypeClaims",)
          img.img-fluid.mx-auto(
            v-if='File.thumb || File.type === "image"',
            :src='File.thumb?File.thumb:File.url' width="60px")
          img.img-fluid.mx-auto.ml-2(
            v-else-if="File.type === 'application/pdf'",
            :src="require('@/assets/images/placeholder-pdf.svg')",
            :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px'}")
          img.img-fluid.mx-auto.ml-2(
            v-else-if="$options.xlsFileType.includes(File.type)",
            :src="require('@/assets/images/xl-placeholder.svg')",
            :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px'}")
          img.img-fluid.mx-auto.ml-2(
            v-else :src="require('@/assets/images/placeholder-zip.svg')",
            :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px'}")
        .col-md-10.pt-3(v-if="(isProductTypeClaims && File.progress !== '0.00') || !isProductTypeClaims")
          .row
            .col-10
              .filename
                |  {{ getFileName(File) }}
            .col-2.text-right(v-if="!rawOutput")
              i.icon-eye.cursor-pointer.mr-2(@click.prevent="preview(File.blob)", v-if="allowFilePreview")
              i.icon-delete.cursor-pointer(v-if="!isReviewModal", @click.prevent="deleteFile(File)")
          .progress.mt-2(v-if="File.active || File.progress !== '0.00'", :style="{height:'7px'}")
                  div(:class="{\
                  'progress-bar': true,\
                  'progress-bar-striped': false,\
                  'bg-danger': File.error,\
                  'bg-teal-600': File.progress == '100',\
                  'progress-bar-animated': File.active,\
                  }" role='progressbar' :style="{ height: '7px', width: File.progress + '%' }")

      template(v-if="!rawOutput")
        .row.align-items-center(v-for='(File, index) in value' :key='File.id')
          .col-md-2.pt-3.text-center
            img.img-fluid.mx-auto.ml-2(v-if='getFileType(File) === "image"', :src='getFileUrl(File)', :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px', objectFit: 'cover'}")
            img.img-fluid.mx-auto.ml-2(v-else-if="getFileType(File) === 'pdf'", :src="require('@/assets/images/placeholder-pdf.svg')", :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px'}")
            img.img-fluid.mx-auto.ml-2(v-else-if="getFileType(File) === 'xls'", :src="require('@/assets/images/xl-placeholder.svg')", :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px', objectFit: 'cover'}")
            img.img-fluid.mx-auto.ml-2(v-else :src="require('@/assets/images/placeholder-zip.svg')", :style="{height: '60px',width:'auto', maxWidth: '100px', maxHeight: '60px'}")
          .col-md-10.pt-3
            .row.align-items-center
              .col-12.col-md-5.align-items-center
                .filename {{ getFileName(File) }}
                span.text-gray-700.font-sm.font-weight-medium {{ timestampFormatter(File.timestamp || File.fileDetails?.createdAt) }}
              .col-12.col-md-7.text-md-right.d-flex.justify-content-end.mt-2
                n-button.mr-2(rightImageIcon="chevron-down", variant="outline-secondary",pill, :buttonText="File.docType?File.docType:'Select Type'", type="button", @click="$bvModal.show(File.id)", size="sm", :disabled="disabled")
                b-modal(:id="File.id", no-footer, title="Upload Documents", hide-footer)
                  template(slot='modal-header')
                    .d-flex.justify-content-between.align-items-center.w-100
                      .py-1.d-flex.align-items-center
                        span.font-lg.font-weight-semibold.mr-2 Select Document Type
                      .py-1
                        b-avatar(variant="none", button, @click="$bvModal.hide(File.id)")
                          i.icon-cross
                  vue-select(:options="options", v-model="File.docType", @input="handleDocTypeChange(File)" )
                  h5.h5.mt-3 Preview
                  .d-flex.justify-content-center.align-items-center.m-5(v-if="getFileType(File) === 'image'")
                    img.img-fluid.mx-auto.ml-2(:src="getFileUrl(File)", :style="{height: '200px',width:'auto', maxWidth: '200px', maxHeight: '200px'}")
                  .d-flex.justify-content-center.align-items-center.m-5(v-else-if="getFileType(File) === 'pdf' && (isProductTypeClaims)")
                    img.img-fluid.mx-auto.ml-2(:src="require('@/assets/images/placeholder-pdf.svg')", :style="{height: '200px',width:'auto', maxWidth: '200px', maxHeight: '200px'}")
                  iframe.w-100.mt-2(v-else-if="getFileType(File) === 'pdf'", :src="getFileUrl(File)", :style="{height: '370px'}")
                  .d-flex.justify-content-center.align-items-center.m-5(v-else-if="getFileType(File) === 'xls'")
                    img.img-fluid.mx-auto.ml-2(:src="require('@/assets/images/xl-placeholder.svg')", :style="{height: '200px',width:'auto', maxWidth: '200px', maxHeight: '200px'}")
                  .d-flex.justify-content-center.align-items-center.m-5(v-else)
                    img.img-fluid.mx-auto.ml-2(:src="require('@/assets/images/placeholder-zip.svg')", :style="{height: '200px',width:'auto', maxWidth: '200px', maxHeight: '200px'}")
                  .text-right
                    n-button.w-25.mt-2(variant="secondary", buttonText="Ok", @click="$bvModal.hide(File.id)")
                n-button(v-if="allowFilePreview", imageIcon="eye", variant="outline-secondary", title="Preview", size="sm", @click="preview(getFileUrl(File))")
                n-button.ml-2(v-if="!isReviewModal", imageIcon="delete", variant="outline-secondary", title="Preview", size="sm", @click.prevent="deleteById(File)")
            vue-select.secondarySelector.mt-2(v-if="allowSecondarySelector", :options="secondaryOptions", v-model="File.policyName", :clearable="false")
          .col-12
            hr.hr
</template>

<script>
import FileUpload from "vue-upload-component";
import VueSelect from "vue-select";
import gql from "graphql-tag";
import moment from "moment";
import { mapGetters } from "vuex";
import adminResDefs from "../portal/pages/admin/definitions";
import { getSingleQuery } from "../portal/pages/admin/queries";
import { ClaimSourceTypes, FileAction } from "../common/enums/";
import NButton from "./NovaButton.vue";

export default {
  components: {
    FileUpload,
    NButton,
    VueSelect,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    uploadedFiles: {
      type: Array,
      default: function () {
        return [];
      },
    },
    accept: {
      type: String,
      default: "image/png,image/gif,image/jpeg,image/webp,text/pdf,pdf,application/pdf",
    },
    extensions: {
      type: String,
      default: "gif,jpg,jpeg,png,webp,pdf,doc",
    },
    uploadTo: {
      type: String,
      default: "aws",
    },
    verifyUpload: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default: function () {
        return [];
      },
    },
    secondaryOptions: {
      type: Array,
      default: function () {
        return [];
      },
    },
    allowSecondarySelector: {
      type: Boolean,
      default: false,
    },
    rawOutput: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
    allowZipDownload: {
      type: Boolean,
      default: true,
    },
    allowFilePreview: {
      type: Boolean,
      default: true,
    },
    singleFilePerOption: {
      type: Boolean,
      default: false,
    },
    uploadFileAction: {
      type: String,
      default: FileAction.FileUpload,
    },
    isReviewModal: {
      type: Boolean,
      default: false,
    },
    productType: {
      type: String,
      default: "fileUpload",
    },
    requiredDetails: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      uploadAuto: true,
      minSize: 1024,
      size: 1024 * 1024 * 3, // 3 MB
      multiple: true,
      directory: false,
      drop: true,
      dropDirectory: true,
      thread: 3,
      name: "file",
      isOption: false,
      loading: false,
      all_files: [...this.value],
      files: [],
      zipFiles: {
        url: null,
        jobId: null,
        interval: null,
        processing: false,
      },
      isDocTypeUpdated: false,
    };
  },
  computed: {
    ...mapGetters(["isAdmin"]),
    showUploadButton() {
      if (!this.singleFilePerOption) {
        return true;
      } else if (this.options.length > this.all_files.length) {
        return true;
      }
      return false;
    },
    isProductTypeClaims() {
      return this.productType === "claims";
    },
  },
  watch: {
    all_files: function () {
      this.$emit("input", this.all_files);
      this.files = [];
    },
    value: function () {
      if (this.rawOutput && this.value !== this.files) {
        this.files = this.value;
      }
    },
    verifyUpload: function () {
      if (this.verifyUpload > 0) {
        if (this.all_files.length === this.files.length) {
          this.$emit("success", [...this.uploadedFiles, ...this.all_files]);
          this.files = [];
        } else {
          this.$emit("failure");
        }
      }
    },
  },
  created() {
    this.$options.xlsFileType = [
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
      "application/vnd.ms-excel",
    ];
  },
  mounted() {
    this.all_files = [...this.value];
  },
  methods: {
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
      }
      if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
        newFile.blob = "";
        const URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        newFile.thumb = "";
        if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
          newFile.thumb = newFile.blob;
        }
      }
    },
    inputFile(newFile, oldFile) {
      this.zipFiles.url = null;
      if (newFile && oldFile === undefined) {
        if (this.uploadAuto && !this.rawOutput) {
          this.sendtodb(newFile);
        }
        if (this.rawOutput) {
          this.files = [newFile];
          this.$emit("input", this.files);
        }
      }
    },
    startFakeProgress(newFile) {
      var step = Math.random(10) / 10;
      var currentProgress = 0;
      const interval = setInterval(function () {
        currentProgress += step;
        newFile.progress = Math.round((Math.atan(currentProgress) / (Math.PI / 2)) * 100 * 1000) / 1000;
        if (newFile.progress >= 100) {
        } else if (newFile.progress >= 70) {
          step = 0.1;
        }
      }, 100);

      return interval;
    },
    failFakeProgress(newFile, progress) {
      clearInterval(progress);
      newFile.error = true;
    },
    completeFakeProgress(newFile, progress) {
      clearInterval(progress);
      return this.productType === "claims" ? (newFile.progress = "0.00") : (newFile.progress = "100");
    },
    async sendtodb(newFile) {
      const progress = this.startFakeProgress(newFile);
      if (this.productType === "claims") {
        try {
          const fileType = this.getFileType(newFile);
          const contentType = fileType === "pdf" ? "application/pdf" : fileType;

          await this.$apollo.mutate({
            mutation: adminResDefs[this.productType].upsertClaimFiles,
            variables: {
              id: this.isDocTypeUpdated ? newFile.id : null,
              claimId: this.requiredDetails.id,
              file: newFile.file,
              docType: newFile.docType,
              createSource: this.isAdmin ? ClaimSourceTypes.PORTAL_SUPER_ADMIN : ClaimSourceTypes.PORTAL_USER,
              isUploaded: false,
              action: this.uploadFileAction,
              fileType: contentType,
            },
          });
          this.completeFakeProgress(newFile, progress);
          this.$root.$emit("claimFileUploaded");
        } catch (err) {
          this.failFakeProgress(newFile, progress);
          this.$store.commit("addToast", {
            variant: "danger",
            message: "Something went wrong, unable to upload file.",
          });
        }
      } else {
        try {
          const result = await this.$apollo.mutate({
            mutation: adminResDefs.files.upsertMutation,
            variables: {
              file: newFile.file,
              action: this.uploadFileAction,
              meta: {
                type: this.getFileType(newFile),
              },
            },
          });

          this.$emit("file-uploaded", result?.data?.upsertFile?.file);
          this.all_files.unshift({
            type: result.data.upsertFile.file.meta?.type,
            url: result.data.upsertFile?.file?.url,
            name: result.data.upsertFile?.file?.name,
            id: result.data.upsertFile?.file?.id,
            timestamp: result.data.upsertFile.file.createdAt,
          });

          for (let i = 0; i < this.files.length; i++) {
            if (this.files[i].file === newFile.file) {
              this.files[i].uploadId = result.data.upsertFile.file.id;
              break;
            }
          }
          this.completeFakeProgress(newFile, progress);
        } catch (err) {
          this.failFakeProgress(newFile, progress);
        }
      }
    },
    getFileType(file) {
      let type = null;
      const fileType = file.type || file.fileDetails?.meta?.type || file.meta?.type;
      if (fileType === "image") {
        return "image";
      }
      if (this.$options.xlsFileType.includes(fileType)) {
        return "xls";
      }
      type = fileType?.split("/")[0];
      if (type === "application") {
        type = fileType.split("/")[1] === "pdf" ? "pdf" : "zip";
      }
      return type;
    },
    getFileName(file) {
      return file.name || (file.fileDetails && file.fileDetails.name) || "";
    },

    getFileUrl(file) {
      return file.url || (file.fileDetails && file.fileDetails.url) || "";
    },

    timestampFormatter(timestamp) {
      return timestamp ? moment(timestamp).format("DD/MM/YYYY, hh:mm a") : "";
    },
    deleteFile(File) {
      this.$refs.upload.remove(File);
      this.$emit("delete-file", File);
      this.$emit("updated", this.all_files);
    },
    async deleteById(file) {
      const id = file.id;
      if (this.productType === "claims") {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation deleteClaimFile($id: ID, $fileId: ID) {
              deleteClaimFile(input: { id: $id, fileId: $fileId }) {
                result
              }
            }
          `,
          variables: {
            id: id,
            fileId: file.fileDetails.id,
          },
        });
      }
      this.$emit("delete-file", this.all_files.filter((file) => file.id === id)[0]);
      this.all_files = this.all_files.filter((file) => file.id !== id);
      this.$emit("input", this.all_files);
    },
    preview(url) {
      window.open(url, "_blank");
    },
    async getZipJob() {
      const job = await this.$apollo.query({
        query: getSingleQuery("Job"),
        fetchPolicy: "network-only",
        variables: {
          id: this.zipFiles.jobId,
        },
      });
      return job.data.node;
    },
    async downloadZip() {
      if (this.zipFiles.url) {
        window.open(this.zipFiles.url, "_blank");
        return;
      }
      this.zipFiles.processing = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation zipFiles($claimId: ID!) {
              zipFiles(input: { claimId: $claimId }) {
                job {
                  id
                }
              }
            }
          `,
          variables: {
            claimId: this.requiredDetails.id,
          },
        });
        this.zipFiles.jobId = result.data.zipFiles?.job?.id;
        this.zipFiles.interval = setInterval(async () => {
          const job = await this.getZipJob();
          if (["completed", "failed"].includes(job.status)) {
            this.zipFiles.url = job.result?.value || null;
            this.zipFiles.processing = false;
            clearInterval(this.zipFiles.interval);
          }
        }, 1000);
      } catch (err) {
        console.log(err);
      }
      this.loading = false;
    },
    handleDocTypeChange(file) {
      this.$bvModal.hide(file.id);
      if (this.productType === "claims") {
        this.isDocTypeUpdated = true;
        this.sendtodb(file);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";
.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
}
.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
.example-full .hr {
  color: $gray-500;
  background-color: $gray-400;
  height: 1px;
}

.secondarySelector::v-deep .vs__dropdown-toggle {
  border-radius: $border-radius-pill;
  padding: 0 0.5rem;
  cursor: pointer;
  width: 100%;

  .vs__search {
    caret-color: transparent;
  }
}
</style>

<style lang="scss">
@import "@/assets/styles/_variables.scss";

.secondarySelector {
  display: flex;

  .vs__selected-options {
    flex-wrap: nowrap;
    transform: translateY(30%);
  }

  .vs__dropdown-toggle {
    border-radius: $border-radius-pill;
    padding: 0 0.5rem;
    cursor: pointer;
  }

  .vs__selected,
  .vs__dropdown-menu {
    color: $gray-800;
    font-size: $btn-font-size-sm;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
  }
}
</style>
